import { useState, useEffect } from "react";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import Legenda from "../../../../components/Legenda";
//Utils
import { dataAtual, formatarData } from "../../../../utils/datas";
import { statusSolicitacao } from "../../../../utils/choices";

//Mui
import { Input, Select, Stack, Typography, Option, Button, Table, Switch, Link } from "@mui/joy";

import { FaArrowUp, FaArrowDown } from "react-icons/fa";
//Material
import { Pagination } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

//API
import { api } from "../../../../services/api";

const dadosUsuarioLogado = {};
let idUsuario = 0;
let token = "";

//Dados do usuário logado no localstorage
const usuarioLogado = JSON.parse(localStorage.getItem('usuario'));

export default function Compras(props) {

    const navigate = useNavigate();

    const [dataInicio, setDataInicio] = useState(dataAtual(-1));
    const [dataTermino, setDataTermino] = useState(dataAtual(1));
    const [status, setStatus] = useState(1);

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [cotacoes, setCotacoes] = useState([]);
    const [paginas, setPaginas] = useState({
        paginaAtual: 1,
        numeroPaginas: 1
    });
    const [verTodasCotacoes, setVerTodasCotacoes] = useState(false);
    const [cotacaoBusca, setCotacaoBusca] = useState("");
    const [ordenaDataEntrega, setOrdenaDataEntrega] = useState(null);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            dadosUsuarioLogado.nome = usuarioLogado.user.nome;
            dadosUsuarioLogado.usuario = usuarioLogado.user.login;
            dadosUsuarioLogado.obra = usuarioLogado.user.obra;
            dadosUsuarioLogado.grupo = usuarioLogado.grupo;

            token = JSON.parse(localStorage.getItem('usuario')).token;
            idUsuario = JSON.parse(localStorage.getItem('usuario')).user.id;

            let permissoes = [];
            if (dadosUsuarioLogado.grupo.length > 0) {
                dadosUsuarioLogado.grupo.forEach((item) => {
                    permissoes.push(item.perfil);
                })
            }
            dadosUsuarioLogado.permissoes = permissoes;
        }
        buscarCotacoes();
    }, []);

    //Recarrega a lista toda vez que clica na coluna data:
    useEffect(() => {
        buscarCotacoes(paginas.paginaAtual);
    }, [ordenaDataEntrega]);

    const handleOrdenaDataEntrega = () => {
        // define estado da variavel ordenaDataAbertura (estados null, true ou false)
        if (ordenaDataEntrega == null){
            setOrdenaDataEntrega(true);
        } else if (ordenaDataEntrega == true){
            setOrdenaDataEntrega(false);
        }
        else if (ordenaDataEntrega == false){
            setOrdenaDataEntrega(null);
        }
    }

    const buscarCotacoes = (pagina = 1) => {

        let urlBuscarCotacoes = `/cotacoes?paginacao=true&page=${pagina}&datasolicitacaoi=${dataInicio}&datasolicitacaof=${dataTermino}&status=${status}&todos=${verTodasCotacoes}&usuario=${idUsuario}&codcotacao=${cotacaoBusca}`;
        //Ordenar data criacao:
        if (ordenaDataEntrega != null){
            urlBuscarCotacoes += `&orderDataEntrega=${ordenaDataEntrega}`;
        }

        api.get(urlBuscarCotacoes,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then((response) => {
            let cotacoesDoBanco = [];
            if (response.data) {
                const dados = response.data;
                dados.data.forEach(cotacao => {
                    cotacoesDoBanco.push(
                        {
                            id: cotacao.id,
                            serie: cotacao.serie,
                            solicitacaoID: cotacao.solicitacao_id,
                            etapaAtual: cotacao.etapaAtual,
                            status: cotacao.status,
                            dataEntrega: formatarData(cotacao.dataEntrega.split(" ")[0]),
                            codCotacao: cotacao.codCotacao,
                            comprador: cotacao.comprador,
                            fornecedor: cotacao.fornecedor,
                            condPagamento: cotacao.condPagamento,
                            tipoFrete: cotacao.tipoFrete,
                            numeroMov6: cotacao.numeroMov6,
                            numeroMov8: cotacao.numeroMov8,
                            numeroMov22: cotacao.numeroMov22,
                            decisao: cotacao.decisao,
                            codloc: cotacao.codloc
                        }
                    );
                });
                setCotacoes(cotacoesDoBanco);
                //Definindo a paginação
                paginas.numeroPaginas = dados.meta.last_page;
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.data.message == "Unauthenticated.") {
                localStorage.setItem("usuario", null);
                navigate("/acessoNegado");
            }
            else {
                toast.error("Ocorreu um erro ao obter as cotações.");
                console.log(error.response.data.message);
            }
            toast.error(JSON.stringify(error.response.data));
        });
    }

    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        buscarCotacoes(value);
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header notification mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt={"50px"} flex={1} height={mobile ? "100%" : "calc(100% - 55px)"} p={mobile ? "20px" : "50px"} overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Movimentos de Compras</Typography>
                <Stack ml={mobile ? "0px" : "20px"}>
                    <Legenda mobile={mobile} />
                    <Stack flexDirection={mobile ? "column" : "row"} mt={mobile ? "20px" : "30px"} alignItems={mobile ? "flex-start" : "center"} justifyContent={"space-between"}>
                        <Stack width="100%" flexDirection={mobile ? "column" : "row"}>
                            <Stack mb={mobile ? "40px" : "0px"} mr={"30px"}>
                                <Typography level="body-sm">N° da Cotação</Typography>
                                <Stack>
                                    <Input value={cotacaoBusca} onChange={e => setCotacaoBusca(e.target.value)} placeholder="0000.000000" sx={{ width: "130px" }} />
                                </Stack>
                            </Stack>
                            <Stack>
                                <Typography level="body-sm">Criadas a partir desta data:</Typography>
                                <Input type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"}>
                                <Typography level="body-sm">Até esta data:</Typography>
                                <Input type="date" value={dataTermino} onChange={(event) => setDataTermino(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Status</Typography>
                                <Select fontSize={12} value={status} onChange={(event, newValue) => setStatus(newValue)} placeholder="Status da Solicitação">
                                    <Option value={1}>Em Andamento</Option>
                                    <Option value={2}>Cancelado</Option>
                                    <Option value={3}>Concluído</Option>
                                </Select>
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mb={mobile ? "40px" : "0px"}>
                                <Typography level="body-sm">Ver todos</Typography>
                                <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                                    <Switch
                                        checked={verTodasCotacoes}
                                        onChange={(event) => setVerTodasCotacoes(event.target.checked)}
                                    />
                                </Stack>
                            </Stack>

                        </Stack>
                        <Stack flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button onClick={() => buscarCotacoes()} color="neutral">ATUALIZAR</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mt="30px">
                    {mobile ?
                        <Stack>
                            {cotacoes.map(function (item, indice) {
                                let podeAprovar = false;
                                let corDaLinha = "";

                                if (dadosUsuarioLogado.permissoes) {

                                    const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == item.etapaAtual
                                        && grupo.obra == item.codloc).length > 0;

                                    if (
                                        ((dadosUsuarioLogado.permissoes.indexOf(item.etapaAtual) != -1) && obraEPermissao)
                                    ) {
                                        podeAprovar = true;
                                        corDaLinha = "#ffe082";
                                    }
                                    if (item.decisao == "Reprovado" && obraEPermissao) {
                                        corDaLinha = "#ef9a9a";
                                    }
                                }

                                return (
                                    <div className="hover">
                                        <Stack key={indice} onClick={() => navigate("/aprovacaoCompras/" + item.numeroMov6, { state: { cotacao: item } })} p={"10px"} boxShadow="md" borderRadius={5} mb={"20px"} sx={{ userSelect: "none" }} style={{ backgroundColor: corDaLinha }}>
                                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Cotação: </Typography>{item.codCotacao}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Solicitação: </Typography>{item.solicitacaoID}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Etapa Atual: </Typography>{item.etapaAtual}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data Entrega: </Typography>{item.dataEntrega}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Comprador: </Typography>{item.comprador}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Fornecedor: </Typography>{item.fornecedor}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Nº 1.1.06: </Typography>{item.numeroMov6}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{statusSolicitacao(item.status)}</Typography>
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                        :
                        <Stack>
                            <Table hoverRow noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "120px" }}>Cotação</th>
                                        <th style={{ height: "25px", width: "80px" }}>Solicitação</th>
                                        <th style={{ height: "25px", width: "180px" }}>Etapa Atual</th>
                                        <th style={{ height: "25px", width: "100px" }}><Link onClick={()=>handleOrdenaDataEntrega()}>Data Entrega {ordenaDataEntrega != null ? ordenaDataEntrega ?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                        <th style={{ height: "25px" }}>Comprador</th>
                                        <th style={{ height: "25px" }}>Fornecedor</th>
                                        <th style={{ height: "25px", width: "100px" }}>N° 1.1.06</th>
                                        <th style={{ height: "25px", width: "120px" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cotacoes.map(function (item, indice) {
                                        let podeAprovar = false;
                                        let corDaLinha = "";

                                        if (dadosUsuarioLogado.permissoes) {

                                            const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == item.etapaAtual
                                                && grupo.obra == item.codloc).length > 0;

                                            if (
                                                ((dadosUsuarioLogado.permissoes.indexOf(item.etapaAtual) != -1) && obraEPermissao)
                                            ) {
                                                podeAprovar = true;
                                                corDaLinha = "#ffe082";
                                            }
                                            if (item.decisao == "Reprovado" && obraEPermissao) {
                                                corDaLinha = "#ef9a9a";
                                            }
                                        }

                                        return (
                                            <tr key={indice} className="hover" onClick={() => navigate("/aprovacaoCompras/" + item.numeroMov6, { state: { cotacao: item } })} style={{ backgroundColor: corDaLinha }} >
                                                <td style={{ padding: "0 10px" }}>{item.codCotacao}</td>
                                                <td style={{ padding: "0 10px" }}>{item.solicitacaoID}</td>
                                                <td style={{ padding: "0 10px" }}>{item.etapaAtual}</td>
                                                <td style={{ padding: "0 10px" }}>{item.dataEntrega}</td>
                                                <td style={{ padding: "0 10px" }}>{item.comprador}</td>
                                                <td style={{ padding: "0 10px" }}>{item.fornecedor}</td>
                                                <td style={{ padding: "0 10px" }}>{item.numeroMov6}</td>
                                                <td style={{ padding: "0 10px", fontWeight: "bold" }}>{statusSolicitacao(item.status)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Stack>
                    }
                    <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                        <Pagination
                            onChange={alterarPagina}
                            count={paginas.numeroPaginas}
                            page={paginas.paginaAtual}
                            hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                            hidePrevButton={paginas.paginaAtual == 1}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );

}
