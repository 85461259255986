import { useState, useEffect } from "react";

// Icones
import { IoWarning, IoLockClosed, IoArrowForward, IoArrowUp, IoArrowDown, IoSettingsSharp } from "react-icons/io5";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";

import { formatarMoedaSemCifrao, formatarMoeda } from '../../../../utils/moedas';

import { toast } from 'react-toastify';

//Mui
import { Stack, Typography, Button, Modal, Sheet, ModalClose, FormHelperText, Textarea, Input, Checkbox, Slider, Link, Select, Option, Table, FormLabel } from "@mui/joy";

import { Box, Stepper, Step, StepLabel, StepContent } from '@mui/material'
import MultipleSelect from 'react-select'

//Roteamento
import { useNavigate, useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api } from "../../../../services/api";

const etapasPermitidasIniciais = [
    {
        'etapa': 'Jurídico',
        '1.1.04': true,
        '1.1.21': false,
    },
    {
        'etapa': 'Aprov. mcp1',
        '1.1.04': true,
        '1.1.21': false,
    },
    {
        'etapa': 'Aprov. mcp2',
        '1.1.04': true,
        '1.1.21': false,
    },
    {
        'etapa': 'Aprov. mcpf',
        '1.1.04': true,
        '1.1.21': false,
    },
    {
        'etapa': 'Aprov. gestor adm suprimentos',
        '1.1.04': true,
        '1.1.21': true,
    },
    {
        'etapa': 'Aprov. gestor contrato/equipamentos',
        '1.1.04': true,
        '1.1.21': true,
    },
    {
        'etapa': 'Aprov. gestor corporativo equipamentos',
        '1.1.04': true,
        '1.1.21': true,
    },
    {
        'etapa': 'Aprov. diretoria operações',
        '1.1.04': true,
        '1.1.21': true,
    },
    {
        'etapa': 'Aprov. diretoria equipamentos',
        '1.1.04': true,
        '1.1.21': true,
    },
    {
        'etapa': 'Aprov. diretoria geral',
        '1.1.04': true,
        '1.1.21': true,
    }
];

const etapasDisponiveis = [
    { value: "Aprov. gestor adm suprimentos", label: "Aprov. gestor adm suprimentos" },
    { value: "Aprov. gestor contrato/equipamentos", label: "Aprov. gestor contrato/equipamentos" },
    { value: "Aprov. diretoria operações", label: "Aprov. diretoria operações" },
    // { value: "Aprov. diretoria suporte operacional", label: "Aprov. diretoria suporte operacional" },
    { value: "Aprov. diretoria equipamentos", label: "Aprov. diretoria equipamentos" },
    { value: "Aprov. gestor corporativo equipamentos", label: "Aprov. gestor corporativo equipamentos" },
    { value: "Aprov. diretoria geral", label: "Aprov. diretoria geral" },
    { value: "Aprov. Special", label: "Aprov. Special" },
    { value: "diligenciamento", label: "diligenciamento" }
];

export default function FluxoAprovacao(props) {

    const navigate = useNavigate();
    const location = useLocation(); //navegacao entre components

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [activeStep, setActiveStep] = useState(0);
    const [modalDadosDaEtapa, setModalDadosDaEtapa] = useState(false);
    const [modalConfirmarExclusao, setModalConfirmarExclusao] = useState(false);
    const [modalCondicoesVisuais, setModalCondicoesVisuais] = useState(false);
    const [modalCondicoesCard, setModalCondicoesCard] = useState(false);
    const [modalEtapasPermitidas, setModalEtapasPermitidas] = useState(false);
    const [modalValorAgregado, setModalValorAgregado] = useState(false);
    const [modalContainers, setModalContainers] = useState(false);
    const [formularioEtapa, setFormularioEtapa] = useState({ id: 0, indice: -1, label: "", regraDeNegocio: "", valores: "", alcada: "", condicao: "", posicao: 1, bloqueado: false, prazo: 0, retornos: [] });
    const [etapas, setEtapas] = useState([]);
    const [condicaoSelecionada, setCondicaoSelecionada] = useState("");
    const [indiceCondicaoSelecionada, setIndiceCondicaoSelecionada] = useState(-1);
    const [etapaExistente, setEtapaExistente] = useState(true);
    const [etapasPermitidas, setEtapasPermitidas] = useState(etapasPermitidasIniciais);
    const [destinos, setDestinos] = useState([]);
    const [modalDestinos, setModalDestinos] = useState(false);
    const [formularioDestino, setFormularioDestino] = useState({ movimento: "", destino: "" });
    const [valoresAgregados, setValoresAgregados] = useState([]);

    const handleNext = (index) => {
        setActiveStep(index);
        setEtapaExistente(true);
    };

    useEffect(() => {
        listarEtapas();
        obterDestinos();
    }, []);

    const listarEtapas = () => {
        api.get('/workflow').then(({ data }) => {
            setEtapas(data.data);
        }).catch(err => {
            toast.error("Ocorreu um erro ao listar etapas");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const atualizarEtapa = (etapasDoProcesso) => {
        api.post(`/workflow`,
            { data: etapasDoProcesso }
        ).then((response) => {
            toast.success("Atualizado com sucesso!");
            listarEtapas();
            setModalDadosDaEtapa(false);
        }).catch(err => {
            toast.error("Erro ao atualizar etapas!");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const consultarEtapa = (indice) => {
        formularioEtapa.indice = indice;
        formularioEtapa.id = etapas[indice].id;
        formularioEtapa.label = etapas[indice].label;
        formularioEtapa.regraDeNegocio = etapas[indice].regraDeNegocio;
        formularioEtapa.valores = etapas[indice].valores;
        formularioEtapa.alcada = etapas[indice].alcada;
        formularioEtapa.prazo = etapas[indice].prazo;
        formularioEtapa.condicao = etapas[indice].condicao;
        formularioEtapa.objeto = JSON.parse(etapas[indice].objeto);
        formularioEtapa.posicao = (indice + 1);
        formularioEtapa.bloqueado = etapas[indice].bloqueado;

        formularioEtapa.retornos = etapasPermitidasIniciais;

        //Verificando se o conteudo de retornos é válido
        if (etapas[indice].retornos) {
            setEtapasPermitidas(JSON.parse(etapas[indice].retornos));
        }
        else {
            toast.warning("Os retornos da etapa selecionada são inválidos!");
            setEtapasPermitidas(etapasPermitidasIniciais);
        }
    }

    const handleChangeFormularioEtapa = (event, name) => {
        if (event) {
            setFormularioEtapa(prevState => ({
                ...prevState,
                [name || event.target.name]: event.target.value || event.target.checked,
            }));
            return;
        }
    }

    const excluirEtapa = () => {
        let etapasDoProcesso = etapas;
        etapasDoProcesso.splice(formularioEtapa.indice, 1);
        setEtapas(etapasDoProcesso);

        api.post(`/workflow`,
            { data: etapasDoProcesso }
        ).then(() => {
            setModalDadosDaEtapa(false);
            setModalConfirmarExclusao(false);
            toast.success("Etapa excluída com sucesso!");
            listarEtapas();
        }).catch(err => {
            toast.error("Erro ao excluir etapa!");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });

        setEtapaExistente(false);
    }

    const alterarEtapa = () => {
        let etapasDoProcesso = etapas;
        const indice = formularioEtapa.indice;

        etapasDoProcesso[indice].label = formularioEtapa.label;
        etapasDoProcesso[indice].regraDeNegocio = formularioEtapa.regraDeNegocio;
        etapasDoProcesso[indice].valores = formularioEtapa.valores;
        etapasDoProcesso[indice].alcada = formularioEtapa.alcada;
        etapasDoProcesso[indice].prazo = formularioEtapa.prazo;
        etapasDoProcesso[indice].condicao = formularioEtapa.condicao;
        etapasDoProcesso[indice].objeto = JSON.stringify(formularioEtapa.objeto);
        etapasDoProcesso[indice].posicao = formularioEtapa.posicao;
        etapasDoProcesso[indice].bloqueado = formularioEtapa.bloqueado;
        etapasDoProcesso[indice].retornos = JSON.stringify(etapasPermitidas);

        // alert("Atualizando")

        etapasDoProcesso = ordenarEtapasPorPosicao(etapasDoProcesso, formularioEtapa.posicao, formularioEtapa.id);
        atualizarEtapa(etapasDoProcesso);
    }

    const ordenarEtapasPorPosicao = (array, posicaoFixa, id) => {
        // Ordena o array pelo atributo "posicao" do objeto de forma crescente.
        const arrayOrdenado = array.sort((obj1, obj2) => obj1.posicao - obj2.posicao);
        let arrayOrdenadoPosicoesCorrigidas = [];
        arrayOrdenado.forEach((etapa, indice) => {
            if ((indice + 1 == posicaoFixa && (etapa.id != id))) {
                etapa.posicao = etapa.posicao + 1;
            }
            arrayOrdenadoPosicoesCorrigidas.push(etapa);
        });

        //Ordenando novamente após o ajuste das posições
        const arrayFinal = arrayOrdenadoPosicoesCorrigidas.sort((obj1, obj2) => obj1.posicao - obj2.posicao);

        return arrayFinal;
    }

    const novaEtapa = () => {
        setEtapaExistente(false);

        formularioEtapa.indice = -1;
        formularioEtapa.label = "";
        formularioEtapa.regraDeNegocio = "";
        formularioEtapa.valores = "";
        formularioEtapa.alcada = "";
        formularioEtapa.prazo = "";
        formularioEtapa.condicao = "";
        formularioEtapa.objeto = "[]";
        formularioEtapa.posicao = etapas.length + 1;
        formularioEtapa.bloqueado = false;

        setModalDadosDaEtapa(true);
    }

    const cadastrarEtapa = () => {

        // console.log(formularioEtapa.objeto);
        // console.log(JSON.stringify(formularioEtapa.objeto));

        let etapasDoProcesso = etapas;

        const novoID = etapasDoProcesso.length == 0 ? 1 : etapasDoProcesso.reduce((maxId, objeto) => {
            return Math.max(maxId, objeto.id);
        }, etapasDoProcesso[0].id) + 1;

        etapasDoProcesso.push({
            id: novoID,
            label: formularioEtapa.label,
            regraDeNegocio: formularioEtapa.regraDeNegocio,
            valores: formularioEtapa.valores,
            alcada: formularioEtapa.alcada,
            prazo: formularioEtapa.prazo,
            condicao: formularioEtapa.condicao,
            objeto: JSON.stringify(formularioEtapa.objeto),
            posicao: formularioEtapa.posicao,
            bloqueado: formularioEtapa.bloqueado,
            retornos: JSON.stringify(formularioEtapa.retornos)
        });

        const novasEtapasProcesso = ordenarEtapasPorPosicao(etapasDoProcesso, formularioEtapa.posicao, novoID);

        //Salvando no banco de dados

        api.post(`/workflow/`,
            { data: novasEtapasProcesso }
        ).then(() => {
            setModalDadosDaEtapa(false);
            toast.success("Etapa criada com sucesso!");
            listarEtapas();
        }).catch(err => {
            toast.error("Erro ao criar etapa!");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const CardCondicao = (props) => {
        return (
            <Stack width="90%" boxShadow={"sm"} alignSelf={"flex-start"} border="1px solid #eee" mb="20px" padding="20px" borderRadius={"10px"}>
                <Typography fontSize={14}>Decisão: <Typography fontWeight={"bold"} sx={props.decisao == "Aprovado" ? { color: "#388e3c" } : props.decisao == "Indiferente" ? { color: "#333" } : { color: "#f44336" }}>{props.decisao}</Typography></Typography>
                <Typography fontSize={14}>Emergencial: {props.emergencial == "Sim" ? "Sim" : "Não"}</Typography>
                {props.tipoPedido.length > 0 ? <Typography fontSize={14}>Tipo de Pedido: {props.tipoPedido ? props.tipoPedido.map((tipo, indice) => { return (<Typography>{(tipo.includes("!=") ? "Dif(" : "Igual(") + tipo.replaceAll("=", "").replaceAll("!", "").trim() + "), "}</Typography>) }) : []}</Typography> : null}
                {props.totalCotacao ? <Typography fontSize={14}>Total de Cotação: {props.totalCotacao}</Typography> : null}
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mt="20px">
                    <Typography sx={{ marginRight: "20px" }}># {props.indiceCondicao + 1}</Typography>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        <Button onClick={() => [setModalCondicoesCard(true), prepararModalCondicao(props.indiceCondicao)]} variant="soft" color="neutral"><IoSettingsSharp size={20} /></Button>
                        <Button disabled={props.indiceCondicao == 0} onClick={() => ordenarCondicao("subir", props.indiceCondicao)} variant="plain"><IoArrowUp size={18} /></Button>
                        <Button disabled={props.ultimo} onClick={() => ordenarCondicao("descer", props.indiceCondicao)} variant="plain"><IoArrowDown size={18} /></Button>
                    </Stack>
                </Stack>
            </Stack >
        )
    }

    const ordenarCondicao = (acao, indice) => {
        let copiaEtapas = [...etapas];
        // console.log(copiaEtapas[activeStep].objeto);
        let condicoes = JSON.parse(JSON.parse(copiaEtapas[activeStep].objeto));

        //Definindo novo indice
        let novoIndice = -1;
        if (acao == "subir") {
            novoIndice = indice - 1;
        } else {
            novoIndice = indice + 1;
        }

        const elementoPrincipal = condicoes[indice];
        const elementoSecundario = condicoes[novoIndice];

        condicoes[novoIndice] = elementoPrincipal;
        condicoes[indice] = elementoSecundario;

        //Gerando condição if
        let condicoesDeIf = "";
        condicoes.forEach(condicao => {
            // console.log(condicao);
            const condicaoIf = gerarCondicaoIf(condicao);
            // console.log(condicaoIf);

            if (condicoesDeIf == "") {
                condicoesDeIf += condicaoIf;
            }
            else {
                condicoesDeIf += "else " + condicaoIf;
            }
        });

        //Atualizando as etapas com as condições da etapa selecionada atualizadas
        copiaEtapas[activeStep].objeto = JSON.stringify(JSON.stringify(condicoes));
        copiaEtapas[activeStep].condicao = condicoesDeIf.trim();

        setEtapas(copiaEtapas);

        setFormularioEtapa(prevState => ({ ...prevState, objeto: JSON.stringify(condicoes) }));
        setFormularioEtapa(prevState => ({ ...prevState, condicao: (condicoesDeIf).trim() }));
    }

    const exibirCondicoes = () => {
        setModalCondicoesVisuais(true);
    }

    const prepararModalCondicao = (indiceCondicao) => {
        setIndiceCondicaoSelecionada(indiceCondicao);
        const dadosCondicao = eval(JSON.parse(etapas[activeStep].objeto))[indiceCondicao];
        let tiposPedido = [];
        let condicaoTipoPedido = null;
        if (dadosCondicao.tipoPedido) {
            dadosCondicao.tipoPedido.forEach(tipo => {
                tiposPedido.push(tipo.replace(/\D/g, ''));
                if (tipo.includes("!=")) {
                    condicaoTipoPedido = "!=";
                }
                else if (tipo.includes("=")) {
                    condicaoTipoPedido = "=";
                }
            });
        }

        let operacaoCotacao = null;
        if (dadosCondicao.totalCotacao) {
            switch (dadosCondicao.totalCotacao.split(" ")[0]) {
                case ">":
                    operacaoCotacao = "maior";
                    break;

                case ">=":
                    operacaoCotacao = "maior ou igual";
                    break;
                case "<":
                    operacaoCotacao = "menor";
                    break;
                case "<=":
                    operacaoCotacao = "menor ou igual";
                    break;

                case "=":
                    operacaoCotacao = "igual";
                    break;

                default:
                    break;
            }
        }

        const condicao = {
            decisao: dadosCondicao.decisao,
            destino: dadosCondicao.destino,
            emergencial: dadosCondicao.emergencial,
            tipoPedido: tiposPedido,
            condicaoTipoPedido: condicaoTipoPedido,
            operacaoCotacao: operacaoCotacao,
            totalCotacao: dadosCondicao.totalCotacao ? dadosCondicao.totalCotacao.replace(/\D/g, '') : ""
        }

        setCondicaoSelecionada(condicao);
    }

    const salvarCondicaoSelecionada = (novo = false, excluir = false) => {
        const copiaEtapas = [...etapas];

        let condicoesDaEtapa = etapaExistente ? eval(JSON.parse(copiaEtapas[activeStep].objeto)) : [];

        let operacaoTotalCotacao = "";
        let condicoesDeIf = "";

        //Preparando o objeto de condição selecionada para o padrão

        let tipoPedidoCondicaoSelecionada = [];
        //Criando condição do tipo pedido formatado
        condicaoSelecionada.tipoPedido.forEach((tipo) => {
            if (condicaoSelecionada.condicaoTipoPedido == "!=") {
                tipoPedidoCondicaoSelecionada.push("!= " + tipo);
            }
            else if (condicaoSelecionada.condicaoTipoPedido == "=") {
                tipoPedidoCondicaoSelecionada.push("= " + tipo);
            }
        });

        let operacaoCondicaoSelecionada = null;
        //Atribuindo operação da cotação
        if (condicaoSelecionada.operacaoCotacao) {
            switch (condicaoSelecionada.operacaoCotacao) {
                case "maior":
                    operacaoCondicaoSelecionada = ">";
                    break;
                case "maior ou igual":
                    operacaoCondicaoSelecionada = ">=";
                    break;
                case "menor":
                    operacaoCondicaoSelecionada = "<";
                    break;
                case "menor ou igual":
                    operacaoCondicaoSelecionada = "<=";
                    break;
                case "igual":
                    operacaoCondicaoSelecionada = "=";
                    break;

                default:
                    break;
            }
        }

        const objetoCondicaoSelecionadaFormatado = {
            decisao: condicaoSelecionada.decisao,
            destino: condicaoSelecionada.destino,
            emergencial: condicaoSelecionada.emergencial,
            tipoPedido: tipoPedidoCondicaoSelecionada,
            totalCotacao: operacaoCondicaoSelecionada ? operacaoCondicaoSelecionada + " " + condicaoSelecionada.totalCotacao : null
        }

        //Atribuindo a condição editada ao array de condições da etapa
        if (novo) {
            condicoesDaEtapa.push(objetoCondicaoSelecionadaFormatado);
        }
        else if (excluir) {
            condicoesDaEtapa.splice(indiceCondicaoSelecionada, 1);
        }
        else {
            condicoesDaEtapa[indiceCondicaoSelecionada] = objetoCondicaoSelecionadaFormatado;
        }

        condicoesDaEtapa.forEach((condicao, indice) => {
            let tipoPedidoFormatado = [];

            //Criando condição do tipo pedido formatado
            if (condicao.tipoPedido) {
                condicao.tipoPedido.forEach((tipo) => {
                    if (condicao.condicaoTipoPedido == "!=") {
                        tipoPedidoFormatado.push("!= " + tipo);
                    }
                    else if (condicao.condicaoTipoPedido == "=") {
                        tipoPedidoFormatado.push("= " + tipo);
                    }
                });
            }

            //Atribuindo operação da cotação
            switch (condicao.operacaoCotacao) {
                case "maior":
                    operacaoTotalCotacao = ">";
                    break;
                case "maior ou igual":
                    operacaoTotalCotacao = ">=";
                    break;
                case "menor":
                    operacaoTotalCotacao = "<";
                    break;
                case "menor ou igual":
                    operacaoTotalCotacao = "<=";
                    break;
                case "igual":
                    operacaoTotalCotacao = "=";
                    break;

                default:
                    break;
            }

            //Preparando objeto para atualizar a condição da etapa selecionada
            const objetoFormatado = {
                decisao: condicao.decisao,
                destino: condicao.destino,
                emergencial: condicao.emergencial == "Sim",
                tipoPedido: condicao.tipoPedido,
                totalCotacao: condicao.totalCotacao
            }

            //Gerando condição if
            const condicaoIf = gerarCondicaoIf(objetoFormatado);

            if (indice == 0) {
                condicoesDeIf += condicaoIf;
            }
            else {
                condicoesDeIf += "else " + condicaoIf;
            }
        });

        //Atualizando objeto json e condição if
        copiaEtapas[activeStep].objeto = JSON.stringify(JSON.stringify(condicoesDaEtapa));
        copiaEtapas[activeStep].condicao = (condicoesDeIf).trim();

        setFormularioEtapa(prevState => ({ ...prevState, objeto: JSON.stringify(condicoesDaEtapa) }));
        setFormularioEtapa(prevState => ({ ...prevState, condicao: (condicoesDeIf).trim() }));

        setEtapas(copiaEtapas);
        setModalCondicoesCard(false);

        setEtapaExistente(true);
    }

    const alterarTipoPedidoCondicao = (decisao, tipo) => {
        let tiposPedido = condicaoSelecionada.tipoPedido;
        if (decisao) {
            tiposPedido.push(tipo);
        } else {
            tiposPedido.splice(tiposPedido.indexOf(tipo), 1);
        }
        setCondicaoSelecionada(prevState => ({ ...prevState, tipoPedido: tiposPedido }));
    }

    const gerarCondicaoIf = (objeto) => {

        //Montando condição do tipo pedido
        let condicaoTipoPedido = "";
        if (objeto.tipoPedido) {
            objeto.tipoPedido.forEach((condicao, indice) => {
                if (indice == 0) {
                    condicaoTipoPedido = " && ("
                }
                if (condicao.includes("!=")) {
                    condicaoTipoPedido += "tipoPedido != '" + condicao.replace(/\D/g, '') + "'";
                    if ((indice + 1) != objeto.tipoPedido.length) {
                        condicaoTipoPedido += " && ";
                    }
                }
                else if (condicao.includes("=")) {
                    condicaoTipoPedido += "tipoPedido == '" + condicao.replace(/\D/g, '') + "'";
                    if ((indice + 1) != objeto.tipoPedido.length) {
                        condicaoTipoPedido += " || ";
                    }
                }

                if ((indice + 1) == objeto.tipoPedido.length) {
                    condicaoTipoPedido += ")";
                }
            });
        }

        //Montando condição do total cotação
        let condicaoTotalCotacao = "";
        if (objeto.totalCotacao) {
            switch (objeto.totalCotacao.split(" ")[0]) {
                case ">":
                    condicaoTotalCotacao = " && totalCotacao > " + objeto.totalCotacao.split(" ")[1];
                    break;
                case ">=":
                    condicaoTotalCotacao = " && totalCotacao >= " + objeto.totalCotacao.split(" ")[1];
                    break;
                case "<":
                    condicaoTotalCotacao = " && totalCotacao < " + objeto.totalCotacao.split(" ")[1];
                    break;
                case "<=":
                    condicaoTotalCotacao = " && totalCotacao <= " + objeto.totalCotacao.split(" ")[1];
                    break;
                case "==":
                    condicaoTotalCotacao = " && totalCotacao == " + objeto.totalCotacao.split(" ")[1];
                    break;

                default:
                    break;
            }
        }

        //Gerando condição para o emergencial
        let condicaoEmergencial = "";
        if (objeto.emergencial == "Sim") {
            condicaoEmergencial = " && emergencial == true";
        }

        //Gerando condição para a decisão
        let condicaoDecisao = "";
        if (objeto.decisao != "Indiferente") {
            condicaoDecisao = "decisao == '" + objeto.decisao + "'";
        }

        const condicaoIf = condicaoDecisao == "" ? ("'" + objeto.destino + "'") : `
            if(${condicaoDecisao}${condicaoEmergencial}${condicaoTipoPedido}${condicaoTotalCotacao}){
                ${objeto.destino != "etapaARetornar" ? ("'" + objeto.destino + "'") : objeto.destino}
            }
        `
        return (condicaoIf.trim());
    }

    const novaCondicao = () => {

        const condicao = {
            decisao: null,
            destino: null,
            emergencial: null,
            tipoPedido: [],
            condicaoTipoPedido: null,
            operacaoCotacao: null,
            totalCotacao: null,
            novo: true
        }

        setCondicaoSelecionada(condicao);

        setModalCondicoesCard(true);
    }

    const consultarEtapasPermitidas = () => {
        //Chamar as etapas permitidas via API e atualizar a variavel de estado etapasPermitidas

        //Se não existir dados da API, carregar valores padrão da variável etapasPermitidasIniciais
        setModalEtapasPermitidas(true)
    }

    const alterarPermissaoEtapa = (permissao, indice, movimento) => {
        let copiaEtapasPermitidas = [...etapasPermitidas];
        copiaEtapasPermitidas[indice][movimento] = permissao;
        setEtapasPermitidas(copiaEtapasPermitidas);
    }

    const consultarDestinos = () => {
        //Chamar API para obter destinos
        setModalDestinos(true);
    }

    const obterDestinos = () => {
        api.get('/movimento').then(({ data }) => {
            setDestinos(data.data);
        }).catch(err => {
            toast.error("Ocorreu um erro ao consultar destinos");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const adicionarDestino = () => {
        const data = {
            origem: formularioDestino.movimento,
            destino: formularioDestino.destino
        }

        // console.log(data);
        api.post('/movimento', data).then(({ data }) => {
            // console.log(data);
            toast.success("Salvo com sucesso!");
            obterDestinos();
        }).catch(err => {
            toast.error("Ocorreu um erro ao adicionar destino");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const excluirDestino = (id) => {
        api.delete('/movimento/' + id).then(({ data }) => {
            // console.log(data);
            toast.success("Excluído com sucesso!");
            obterDestinos();
        }).catch(err => {
            toast.error("Ocorreu um erro ao excluir destino");
            console.log(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const salvarRetornos = () => {
        formularioEtapa.retornos = etapasPermitidas;
        setModalEtapasPermitidas(false);
    }

    const consultarValoresAgregados = () => {
        api.get('/valoresAgregados').then(res => {
            // console.log(res.data.data);
            setValoresAgregados(res.data.data);
        })
        setModalValorAgregado(true);
    }

    const consultarContainers = () => {
        setModalContainers(true);
    }

    const atualizarValorAgregado = (indice, atributo, novoValor) => {
        let copiaValoresAgregados = [...valoresAgregados];
        copiaValoresAgregados[indice][atributo] = parseFloat(novoValor);
        setValoresAgregados(copiaValoresAgregados);
    }

    const atualizarValoresAgregados = async () => {
        // console.log(valoresAgregados)

        for (const valorAgregado of valoresAgregados) {
            const data = {
                identificador: valorAgregado.identificador,
                descricao: valorAgregado.descricao,
                valorMin: valorAgregado.valorMin,
                valorMax: valorAgregado.valorMax
            }

            const res = await api.put('/valoresAgregados/' + valorAgregado.id, data);
            console.log(res.data);
        }

        toast.success("Valores agregados atualizados com sucesso!");
        setModalValorAgregado(false);
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header mobile={mobile} notification />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">Workflow</Typography>
                <Stack flexDirection="row" alignItems="center" justifyContent={"flex-end"}>
                    <Button sx={{ marginRight: "15px" }} onClick={consultarContainers} color="neutral">Containers</Button>
                    <Button sx={{ marginRight: "15px" }} onClick={consultarValoresAgregados} color="neutral">Valores Agregados</Button>
                    <Button sx={{ marginRight: "15px" }} onClick={consultarDestinos} color="neutral">Destinos</Button>
                    <Button onClick={novaEtapa}>Nova Etapa</Button>
                </Stack>
                <Stack mt="50px">
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {etapas.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    StepIconComponent={() => <Stack backgroundColor="#1565c0" padding="3px 10px" borderRadius={"sm"} color="#eee" fontWeight={"bold"}>{index + 1}</Stack>}
                                >
                                    <Stack flexDirection="row" alignItems="center">
                                        <Typography className="hover" sx={{ marginRight: "5px" }} onClick={() => handleNext(index)} level={index == activeStep ? "title-lg" : "title-md"}>{step.label}</Typography>
                                        {step.bloqueado ? <IoLockClosed fontSize={16} color="#444" /> : null}
                                    </Stack>
                                </StepLabel>
                                <StepContent>
                                    <Stack width={mobile ? "85%" : "40%"} bgcolor={"#fff"} border="1px solid #eee" padding="20px" borderRadius={"10px"}>
                                        <Typography level="body-sm" sx={{ marginBottom: "10px" }}><Typography level="body-md">Regra de Negócio: </Typography>{step.regraDeNegocio || "N/A"}</Typography>
                                        <Typography level="body-sm" sx={{ marginBottom: "10px" }}><Typography level="body-md">Faixa de Valores: </Typography>{step.valores || "N/A"}</Typography>
                                        <Typography level="body-sm" sx={{ marginBottom: "10px" }}><Typography level="body-md">Alçada: </Typography>{step.alcada || "N/A"}</Typography>
                                        <Typography level="body-sm" sx={{ marginBottom: "10px" }}><Typography level="body-md">Prazo: </Typography>{step.prazo || "Sem prazo"}</Typography>
                                        {/* <Typography level="body-sm" sx={{ marginBottom: "10px" }}><Typography level="body-md">Pedido Emergencial: </Typography>{step.emergencial || "N/A"}</Typography> */}
                                        <Box>
                                            <Button
                                                onClick={() => [consultarEtapa(index), setModalConfirmarExclusao(true)]}
                                                color="danger"
                                                variant="outlined"
                                                disabled={step.bloqueado == 1}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Excluir
                                            </Button>
                                            <Button
                                                onClick={() => [consultarEtapa(index), setModalDadosDaEtapa(true)]}
                                                sx={{ mt: 1, mr: 1 }}
                                                variant="outlined"
                                            >
                                                Editar
                                            </Button>
                                        </Box>
                                        {step.bloqueado ?
                                            <Stack flexDirection={"row"} alignItems="center" mt="10px">
                                                <IoWarning color="orange" />
                                                <FormHelperText sx={{ marginLeft: "3px" }}>Etapas com vínculos no código não podem ser excluídas</FormHelperText>
                                            </Stack>
                                            : null
                                        }
                                    </Stack>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Stack>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalDadosDaEtapa}
                onClose={() => setModalDadosDaEtapa(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 400,
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        {formularioEtapa.label}
                    </Typography>
                    <Stack height={mobile ? "90%" : "600px"} overflow={"auto"} padding="10px 20px 10px 10px">
                        <Stack mt="20px">
                            <Typography id="modal-desc" textColor="text.tertiary">
                                Nome da Etapa:
                            </Typography>
                            <Input value={formularioEtapa.label} onChange={(e) => handleChangeFormularioEtapa(e, 'label')} placeholder="Nome da etapa"></Input>
                        </Stack>
                        <Stack mt="20px">
                            <Typography id="modal-desc" textColor="text.tertiary">
                                Regra de Negócio:
                            </Typography>
                            <Textarea value={formularioEtapa.regraDeNegocio} onChange={(e) => handleChangeFormularioEtapa(e, 'regraDeNegocio')} minRows={2} placeholder="Regras de negócio da etapa"></Textarea>
                        </Stack>
                        <Stack mt="20px">
                            <Typography id="modal-desc" textColor="text.tertiary">
                                Faixa de valores:
                            </Typography>
                            <Input value={formularioEtapa.valores} onChange={(e) => handleChangeFormularioEtapa(e, 'valores')} placeholder="Informe a faixa de valores desta etapa"></Input>
                        </Stack>
                        <Stack mt="20px">
                            <Typography id="modal-desc" textColor="text.tertiary">
                                Alçada:
                            </Typography>
                            <Input value={formularioEtapa.alcada} onChange={(e) => handleChangeFormularioEtapa(e, 'alcada')} placeholder="Alçada da etapa de aprovação"></Input>
                        </Stack>
                        <Stack mt="20px">
                            <Typography id="modal-desc" textColor="text.tertiary">
                                Prazo:
                            </Typography>
                            <Input value={formularioEtapa.prazo} onChange={(e) => handleChangeFormularioEtapa(e, 'prazo')} placeholder="Prazo da etapa de aprovação"></Input>
                        </Stack>
                        <Stack mt="20px">
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography id="modal-desc" textColor="text.tertiary">
                                    Condição:
                                </Typography>
                                {formularioEtapa.label != "Comprador" ? <Link onClick={exibirCondicoes}>Editar condições</Link> : null}
                            </Stack>
                            <Textarea disabled value={formularioEtapa.condicao} onChange={(e) => handleChangeFormularioEtapa(e, 'condicao')} minRows={2} placeholder="Código que será executado na etapa"></Textarea>
                        </Stack>
                        <Stack mt="20px">
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography id="modal-desc" textColor="text.tertiary">
                                    Objeto:
                                </Typography>
                            </Stack>
                            <Textarea disabled value={formularioEtapa.objeto} onChange={(e) => handleChangeFormularioEtapa(e, 'objeto')} minRows={2} placeholder="Código que será executado na etapa"></Textarea>
                        </Stack>
                        {formularioEtapa.label != "Solicitante" && formularioEtapa.label != "Aprov. Técnico" && formularioEtapa.label != "Aprov. Gerencial" && formularioEtapa.label != "Aprov. Institucional" && formularioEtapa.label != "Compras" && formularioEtapa.label != "Comprador" ?
                            <Stack mt="20px">
                                <Link onClick={consultarEtapasPermitidas} alignSelf={"center"}>Etapas Permitidas para Volta</Link>
                            </Stack>
                            : null
                        }
                        <Stack mt="20px">
                            <Typography sx={{ marginBottom: "18px" }} textColor="text.tertiary">Posição da Etapa:</Typography>
                            <Slider
                                aria-label="Small steps"
                                defaultValue={formularioEtapa.posicao}
                                // getAriaValueText={""}
                                step={1}
                                marks
                                min={1}
                                max={formularioEtapa.indice == -1 ? (etapas.length + 1) : etapas.length}
                                valueLabelDisplay="on"
                                // value={formularioEtapa.posicao} 
                                onChange={(e) => handleChangeFormularioEtapa(e, 'posicao')}
                            />
                        </Stack>
                        <Stack mt="20px" flexDirection={"row"} alignItems="center">
                            <Checkbox checked={formularioEtapa.bloqueado == 1} onChange={(e) => handleChangeFormularioEtapa(e, 'bloqueado')} />
                            <Typography sx={{ marginLeft: "8px" }} id="modal-desc" textColor="text.tertiary">Bloqueado para exclusão</Typography>
                        </Stack>
                        <Stack mt="40px" flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                            <Button disabled={formularioEtapa.bloqueado == 1} onClick={() => setModalConfirmarExclusao(true)} color="danger">Excluir</Button>
                            <Button onClick={formularioEtapa.indice == -1 ? cadastrarEtapa : alterarEtapa}>Salvar</Button>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalConfirmarExclusao}
                onClose={() => setModalConfirmarExclusao(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Exclusão de Etapa
                    </Typography>
                    <Stack mt="20px">
                        <Typography>
                            Tem certeza que deseja excluir?
                        </Typography>
                    </Stack>
                    <Stack mt="20px" flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                        <Button variant="soft" onClick={() => setModalConfirmarExclusao(false)}>Cancelar</Button>
                        <Button color="danger" onClick={excluirEtapa}>Excluir</Button>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalCondicoesCard}
                onClose={() => setModalCondicoesCard(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        minWidth: 400,
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Condições
                    </Typography>
                    <Stack overflow={"auto"} padding="10px 20px 10px 10px">
                        <Stack mb="20px">
                            <Typography>Decisão</Typography>
                            <Select value={condicaoSelecionada.decisao} onChange={(e, newValue) => setCondicaoSelecionada(prevState => ({ ...prevState, decisao: newValue }))}>
                                <Option value="Aprovado">Aprovado</Option>
                                <Option value="Reprovado">Reprovado</Option>
                                <Option value="Indiferente">Indiferente</Option>
                            </Select>
                        </Stack>
                        <Stack mb="20px">
                            <Typography>Emergencial</Typography>
                            <Select value={condicaoSelecionada.emergencial} onChange={(e, newValue) => setCondicaoSelecionada(prevState => ({ ...prevState, emergencial: newValue }))}>
                                <Option value="Sim">Sim</Option>
                                <Option value="Não">Não</Option>
                            </Select>
                        </Stack>
                        <Stack mb="20px">
                            <Typography>Operação do Tipo Pedido</Typography>
                            <Select value={condicaoSelecionada.condicaoTipoPedido} onChange={(e, newValue) => setCondicaoSelecionada(prevState => ({ ...prevState, condicaoTipoPedido: newValue }))}>
                                <Option value={null}>Nenhum</Option>
                                <Option value="=">Igual</Option>
                                <Option value="!=">Diferente</Option>
                            </Select>
                        </Stack>
                        <Stack mb="20px">
                            <Typography>Tipo de Pedido</Typography>
                            {condicaoSelecionada.tipoPedido ?
                                <Stack flexDirection={"row"} flexWrap={"wrap"} mt="10px">
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("001") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "001")} label="001" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("002") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "002")} label="002" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("003") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "003")} label="003" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("004") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "004")} label="004" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("005") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "005")} label="005" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("006") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "006")} label="006" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("007") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "007")} label="007" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("008") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "008")} label="008" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("009") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "009")} label="009" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("010") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "010")} label="010" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("011") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "011")} label="011" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("012") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "012")} label="012" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("013") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "013")} label="013" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                    <Checkbox checked={condicaoSelecionada.tipoPedido.indexOf("014") != -1} onChange={(e) => alterarTipoPedidoCondicao(e.target.checked, "014")} label="014" sx={{ width: "80px", marginBottom: "10px", fontSize: 14 }} />
                                </Stack>
                                :
                                null
                            }
                        </Stack>
                        <Stack mb="30px">
                            <Typography>Total de Cotação</Typography>
                            <Stack flexDirection={"row"} alignItems={"row"}>
                                <Select value={condicaoSelecionada.operacaoCotacao} onChange={(e, newValue) => setCondicaoSelecionada(prevState => ({ ...prevState, operacaoCotacao: newValue }))} sx={{ width: "200px", marginRight: "20px" }}>
                                    <Option value={null}>Nenhum</Option>
                                    <Option value="igual">Igual</Option>
                                    <Option value="maior">Maior que</Option>
                                    <Option value="maior ou igual">Maior ou igual que</Option>
                                    <Option value="menor">Menor que</Option>
                                    <Option value="menor ou igual">Menor ou igual que</Option>
                                </Select>
                                <Input value={condicaoSelecionada.totalCotacao} onChange={(e) => setCondicaoSelecionada(prevState => ({ ...prevState, totalCotacao: e.target.value }))} sx={{ flex: 1 }} />
                            </Stack>
                        </Stack>
                        <Stack mb="20px">
                            <Typography>Etapa de Destino</Typography>
                            <Select value={condicaoSelecionada.destino} onChange={(e, newValue) => setCondicaoSelecionada(prevState => ({ ...prevState, destino: newValue }))}>
                                {etapas.map((etapa, indice) => {
                                    return (
                                        <Option key={indice} value={etapa.label}>{etapa.label}</Option>
                                    )
                                })}
                                <Option value={"etapaARetornar"}>etapa a retornar</Option>
                            </Select>
                        </Stack>
                        <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            {condicaoSelecionada.novo ? null : <Button onClick={() => salvarCondicaoSelecionada(false, true)} color="danger">Excluir</Button>}
                            <Button onClick={() => salvarCondicaoSelecionada(condicaoSelecionada.novo)}>Salvar</Button>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalCondicoesVisuais}
                onClose={() => setModalCondicoesVisuais(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? '90%' : '400px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Editor de Condições
                    </Typography>
                    <Stack width="100%" height={mobile ? "90%" : "70vh"} overflow={"auto"} padding="10px">
                        {etapas.length > 0 ?
                            <Stack width="95%">
                                {etapaExistente ?
                                    <Stack>
                                        {/* {console.log(JSON.parse(etapas[activeStep].objeto))} */}
                                        {eval(JSON.parse(etapas[activeStep].objeto)).map((condicao, indice) => {
                                            return (
                                                <CardCondicao
                                                    key={indice}
                                                    indiceCondicao={indice}
                                                    decisao={condicao.decisao}
                                                    destino={condicao.destino}
                                                    emergencial={condicao.emergencial}
                                                    tipoPedido={condicao.tipoPedido}
                                                    totalCotacao={condicao.totalCotacao}
                                                    ultimo={eval(JSON.parse(etapas[activeStep].objeto)).length == (indice + 1)}
                                                />
                                            );
                                        })}
                                    </Stack>
                                    : null}
                            </Stack>
                            : null
                        }
                    </Stack>
                    <Button onClick={novaCondicao} sx={{ width: "100%" }}>Nova Condição</Button>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalEtapasPermitidas}
                onClose={() => setModalEtapasPermitidas(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Etapas Permitidas para Volta
                    </Typography>
                    <Stack width="100%" height={mobile ? "90%" : "70vh"} overflow={"auto"} padding="10px">
                        <Stack flex={1}>
                            <Table hoverRow noWrap>
                                <thead>
                                    <th style={{ textAlign: "left" }}>Etapa</th>
                                    <th style={{ width: "150px", textAlign: "center" }}>1.1.04</th>
                                    <th style={{ width: "150px", textAlign: "center" }}>1.1.21</th>
                                </thead>
                                <tbody>
                                    {etapasPermitidas.map((etapaPermitida, indice) => {
                                        return (
                                            <tr key={indice}>
                                                <td style={{ textAlign: "left", height: "15px", padding: "10px 5px" }}>{etapaPermitida.etapa}</td>
                                                <td style={{ width: "150px", textAlign: "center", height: "15px" }}><Checkbox checked={etapaPermitida['1.1.04']} onChange={(e) => alterarPermissaoEtapa(e.target.checked, indice, '1.1.04')} /></td>
                                                <td style={{ width: "150px", textAlign: "center", height: "15px" }}><Checkbox checked={etapaPermitida['1.1.21']} onChange={(e) => alterarPermissaoEtapa(e.target.checked, indice, '1.1.21')} /></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <Stack flex={1} mt="20px" flexDirection={'row'} justifyContent={"flex-end"} alignItems={'flex-end'}>
                                <Button onClick={salvarRetornos}>Salvar</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalDestinos}
                onClose={() => setModalDestinos(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Destino dos Movimentos
                    </Typography>
                    <Stack width="100%" height={mobile ? "90%" : "70vh"} overflow={"auto"} padding="10px">
                        <Stack flex={1}>
                            <Stack flexDirection={"row"} alignItems="flex-end">
                                <Stack width="100px">
                                    <FormLabel>Movimento</FormLabel>
                                    <Input value={formularioDestino.movimento} onChange={(e) => setFormularioDestino(prevState => ({ ...prevState, movimento: e.target.value }))} placeholder="1.1.04" />
                                </Stack>
                                <Stack flex={1} ml="15px">
                                    <FormLabel>Destino</FormLabel>
                                    <Select value={formularioDestino.destino} onChange={(e, newValue) => setFormularioDestino(prevState => ({ ...prevState, destino: newValue }))} placeholder="Etapa de destino">
                                        {etapas.map((etapa, indice) => {
                                            return (
                                                <Option key={indice} value={etapa.label}>{etapa.label}</Option>
                                            );
                                        })}
                                    </Select>
                                </Stack>
                                <Button onClick={adicionarDestino} sx={{ marginLeft: "20px" }}>Adicionar</Button>
                            </Stack>
                            <Stack mt="40px">
                                <Typography level="title-md" sx={{ fontWeight: "bold", color: "#444" }}>Destinos Cadastrados</Typography>
                                <Stack mt="10px">
                                    {destinos.map((destino, indice) => {
                                        return (
                                            <Stack key={indice} mb="10px" flexDirection={"row"} alignItems={"center"}>
                                                <Stack flex={1} flexDirection={"row"} alignItems={"center"}>
                                                    <Typography sx={{ marginRight: "20px" }}>{destino.origem}</Typography>
                                                    <IoArrowForward />
                                                    <Typography sx={{ marginLeft: "20px" }}>{destino.destino}</Typography>
                                                </Stack>
                                                <Button onClick={() => excluirDestino(destino.id)} color="danger">Excluir</Button>
                                            </Stack>
                                        )
                                    })}

                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalValorAgregado}
                onClose={() => setModalValorAgregado(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Valores Agregados
                    </Typography>
                    <Stack width="100%" height={mobile ? "90%" : "70vh"} overflow={"auto"} padding="10px">
                        <Stack flex={1} mt="10px">
                            {valoresAgregados.map((valorAgregado, indice) => {
                                return (
                                    <Stack key={indice} mb="20px" backgroundColor="#eee" p={"15px 15px"} borderRadius={"10px"}>
                                        <Typography level="title-md">{valorAgregado.descricao}</Typography>
                                        <Stack flexDirection={"row"} mt="5px" justifyContent={"space-between"} alignItems={"center"}>
                                            <Typography>De </Typography>
                                            <Input
                                                value={valorAgregado.valorMin}
                                                onChange={e => atualizarValorAgregado(indice, "valorMin", e.target.value)}
                                                type="number"
                                                placeholder="0.00"
                                                sx={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}
                                                startDecorator={<Typography sx={{ color: "#aaa" }}>R$</Typography>}
                                            />
                                            <Typography> até </Typography>
                                            <Input
                                                value={valorAgregado.valorMax}
                                                onChange={e => atualizarValorAgregado(indice, "valorMax", e.target.value)}
                                                type="number"
                                                placeholder="0.00"
                                                sx={{ flex: 1, marginLeft: "10px" }}
                                                startDecorator={<Typography sx={{ color: "#aaa" }}>R$</Typography>}
                                            />
                                        </Stack>
                                    </Stack>
                                );
                            })}
                        </Stack>
                        <Button onClick={atualizarValoresAgregados} sx={{ alignSelf: "flex-end" }}>Salvar</Button>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalContainers}
                onClose={() => setModalContainers(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? '90%' : '600px',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Containers
                    </Typography>
                    <Stack width="100%" height={mobile ? "90%" : "70vh"} overflow={"auto"} padding="10px">
                        <Stack flex={1} mt="10px">
                            <Stack mb="20px" backgroundColor="#eee" p={"15px 15px"} borderRadius={"10px"}>
                                <Typography level="title-md">Nome do Container</Typography>
                                <Stack mt="5px">
                                    {/* <Input
                                        // value={valorAgregado.valorMin}
                                        // onChange={e => atualizarValorAgregado(indice, "valorMin", e.target.value)}
                                        placeholder="AAA"
                                        sx={{ flex: 1 }}
                                    /> */}
                                    <Typography fontWeight={"bold"}>AAA</Typography>
                                    <Typography mt="15px">Etapas do Container</Typography>
                                    <MultipleSelect
                                        // value={novoUsuario ? telasPermitidas : usuarioConsultado.telas_permitidas}
                                        // onChange={(e) => novoUsuario ? setTelasPermitidas(e) : setUsuarioConsultado({ ...usuarioConsultado, telas_permitidas: e })}
                                        placeholder=""
                                        isMulti
                                        options={etapasDisponiveis}
                                        noOptionsMessage={() => "Sem opções"}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Button onClick={atualizarValoresAgregados} sx={{ alignSelf: "flex-end" }}>Salvar</Button>
                    </Stack>
                </Sheet>
            </Modal>
        </Stack >
    );
}